<template>
  <a-modal
    title="详情"
    width="50%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="confirmLoading">
      <a-row>
        <a-form layout="inline">
          <a-col>
            <!-- 商品信息 -->
            <a-row>
              <a-col :md="24" :sm="24">
                <a-form-item label="商品名称">
                  <span>{{ details.name }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="商品分类">
                  <span>{{ details.goodsCategoryName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="商品关键字">
                  <span>{{ details.keywords }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="规格类型">
                  <span>{{ details.speType == 1 ? '单规格' : details.speType == 2 ? '多规格' : '' }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="参考价">
                  <span>{{ details.price }}</span>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="12" :sm="24">
                                <a-form-item  label="邮费">
                                    <span>{{details.postage}}</span>
                                </a-form-item>
                            </a-col> -->
              <a-col :md="12" :sm="24">
                <a-form-item label="退货政策">
                  <span>{{
                    details.returnGoodsPolicy == 1 ? '七天退货' : details.returnGoodsPolicy == 2 ? '不退货' : ''
                  }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="封面图">
                  <viewer :images="[details.coverImage]">
                    <img :src="details.coverImage" style="border: 0.5px solid #ccc; padding: 5px; margin: 3px" alt="" />
                  </viewer>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" v-if="details.rotationImages">
                <a-form-item label="轮播图">
                  <div style="display: flex">
                    <video
                      :src="videoSrc"
                      controls="controls"
                      v-if="videoSrc"
                      style="border: 0.5px solid #ccc; padding: 5px; margin: 3px; width: 80px; height: 80px"
                    />
                    <viewer :images="rotationImages">
                      <img
                        v-for="(item, index) in rotationImages"
                        style="border: 0.5px solid #ccc; padding: 5px; margin: 3px; width: 80px; height: 80px"
                        :key="index"
                        :src="item"
                      />
                    </viewer>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" v-if="details.detail">
                <a-form-item label="商品详情" style="display: flex">
                  <span class="detail" v-html="details.detail"></span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 规格信息 -->
            <a-row v-if="details.specificationsList && Array.isArray(details.specificationsList)">
              <a-divider>规格</a-divider>
              <a-col :md="24" :sm="24">
                <a-table
                  :columns="columns"
                  bordered
                  :data-source="details.specificationsList"
                  :scroll="{ x: 580 }"
                  :pagination="false"
                >
                  <!-- 规格图片插槽 -->
                  <span slot="specImage" slot-scope="text">
                    <viewer :images="[text]">
                      <img :src="text" />
                    </viewer>
                  </span>
                </a-table>
              </a-col>
            </a-row>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { commodityDetail } from '@/api/modular/mallLiving/commodity/commodityList'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '规格名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '单价（元）',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
  },
  {
    title: '最低价（元）',
    dataIndex: 'minPrice',
    key: 'minPrice',
    align: 'center',
  },

  {
    title: '库存',
    dataIndex: 'sales',
    key: 'sales',
    align: 'center',
  },
  {
    title: '规格图片',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    scopedSlots: {
      customRender: 'specImage',
    },
  },
]

export default {
  data() {
    return {
      columns,
      visible: false, //modal框显示状态
      confirmLoading: false,
      indexBigImg: false,
      lunboBigImg: false,
      id: '',
      details: {},
      imagesList: [],
      indexBig: '',
      lunboBig: '',
    }
  },
  computed: {
    videoSrc() {
      let data = this.details.rotationImages.split(',')
      data = data.filter((item) => item.includes('.mp4') || item.includes('.webm') || item.includes('.ogg'))
      console.log(data[0], 'videoSrc')
      return data[0]
    },

    rotationImages() {
      let data = this.details.rotationImages.split(',')
      data = data.filter((item) => !item.includes('.mp4') && !item.includes('.webm') && !item.includes('.ogg'))
      return data
    },
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true
      //获取详情数据
      setTimeout(() => {
        commodityDetail({ id: record.id }).then((res) => {
          if (res.success) {
            this.details = res.data
            this.confirmLoading = false
          }
        })
      }, 200)
    },
    // 展示大图
    showImg(item) {
      this.showBigImg = true
      this.bigImg = item
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
      this.details = {}
      this.imagesList = []
    },
    // 关闭展示大图弹框
    handleCancel_1() {
      this.showBigImg = false
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}

.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;

  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }

  .con {
    width: 30%;
  }

  td {
    border: 2px solid rgb(228, 225, 225);
    padding-left: 7px;
    font-size: 15px;
  }
}

img {
  width: 100px;
}

.detail {
  display: block;
  border: 0.5px solid #ccc;
  padding: 0 5px;
  margin-top: 10px;
}
</style>
